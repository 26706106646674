<template>
  <div class="bg-light">
    <Header></Header>
    <div class="container" style="margin-top: 20px">
      <el-row :gutter="20">
        <el-col :md="8" :sm="24" class="animated fadeInLeft">
          <el-card>
            <div>
              <img :src="avatarUrl" alt="" class="avatarImage">
              <div style="margin-left: 20px">
                <span class="username">{{ username }}</span><br>
                <el-button style="margin-left: 20px" size="small" type="primary" @click="goHome">首页</el-button>
                <el-button size="small" @click="goMypage">我的主页</el-button>
              </div>
            </div>
          </el-card>
          <el-card style="margin-top: 20px ;text-align: center">
            <el-button type="primary" @click="dialogVisible = true" icon="el-icon-plus" circle></el-button>
            创建新的博客
          </el-card>
          <el-card style="margin-top: 20px">
            <el-skeleton :rows="6" animated/>
          </el-card>
        </el-col>
        <el-col :md="16" :sm="24" class="animated fadeInRight">
          <el-row style="margin: 15px 0">
            <el-col :span="12" :offset="4">
              <el-input
                placeholder="请输入内容"
                clearable>
              </el-input>
            </el-col>
            <el-col :span="6" :offset="1">
              <el-button style="width: 130px" icon="el-icon-search" type="primary" round>查找
              </el-button>
            </el-col>
          </el-row>
          <div style="text-align: center;">
            <el-pagination
              class="bg-light"
              :hide-on-single-page="false"
              :current-page.sync="currentPage"
              :page-size="pageSize"
              @current-change="showBlogsByAuthorId"
              :total="total"
              layout="prev, pager, next">
            </el-pagination>
          </div>
          <div class="block" v-loading="loading">
            <el-timeline v-for="blog in blogs" :key="blog.id">
              <el-timeline-item :timestamp="blog.gmtCreate" placement="top">
                <el-card>
                  <el-link @click="goBlogDetails(blog.id)"><h4 style="margin: 3px 0">{{ blog.title }}</h4></el-link>
                  <el-button style="float: right" size="small" type="primary" icon="el-icon-edit"
                             @click="goBlogEdit(blog.id)">编辑
                  </el-button>
                  <el-button style="float: right;margin-right: 10px" size="small" type="danger" icon="el-icon-delete"
                             @click="removeBlogById(blog.id)">删除
                  </el-button>
                  <p>最后更新于 {{ blog.gmtModified }}</p>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>

        </el-col>
      </el-row>
    </div>
    <el-dialog
      title="新建Blog"
      :visible.sync="dialogVisible"
      width="40%">
      <CreateBlog></CreateBlog>
    </el-dialog>
  </div>
</template>

<script>
import Header from '@/components/Header'
import axios from 'axios'
import CreateBlog from '@/components/BlogTools/CreateBlog'

export default {
  name: 'MyBlogs',
  components: { CreateBlog, Header },
  data () {
    return {
      loading: false,
      authorId: 0,
      blogs: [],
      form: {
        title: ''
      },
      currentPage: 1,
      total: 1,
      pageSize: 5,
      rules: {
        title: [
          {
            required: true,
            message: '请输入标题',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 15,
            message: '长度在 3 到 15 个字符',
            trigger: 'blur'
          }
        ]
      },
      dialogVisible: false,
      avatarUrl: '',
      username: ''
    }
  },
  created () {
    this.authorId = this.$route.params.authorId
    this.checkLogin()
    this.showBlogsByAuthorId()
  },
  methods: {
    removeBlogById (id) {
      this.$confirm('确定删除此博客吗?')
        .then(_ => {
          axios({
            method: 'POST',
            url: 'https://mooc.kirinnorine.top/blog/removeBlogById',
            params: {
              id: id
            }
          }).then(res => {
            if (res.data.status) {
              this.$message.success(res.data.message)
              this.showBlogsByAuthorId()
            } else {
              this.$message.error(res.data.message)
            }
          })
        })
        .catch(_ => {
        })
    },
    goBlogDetails (blogId) {
      this.$router.push({
        name: 'BlogDetails',
        params: {
          blogId: blogId
        }
      })
    },
    showBlogsByAuthorId () {
      this.loading = true
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/blog/showBlogsByAuthorDesc',
        params: {
          authorId: this.authorId,
          authorType: 'student',
          currentPage: this.currentPage,
          pageSize: 5
        }
      }).then(res => {
        if (res.data.status) {
          console.log('res:', res.data.data)
          this.blogs = res.data.data.content
          this.total = res.data.data.totalCount
          this.loading = false
        }
      })
    },
    goHome () {
      this.$router.push({ path: '/' })
    },
    goMypage () {
      this.$router.push({ path: '/mypage' })
    },
    checkLogin () {
      axios({
        methods: 'GET',
        url: 'https://mooc.kirinnorine.top/student/checkLogin'
      }).then(response => {
        if (response.data.status) {
          this.username = response.data.data.name
          const email = response.data.data.email
          this.avatarUrl = 'http://q1.qlogo.cn/g?b=qq&nk=' + 565843270 + '&s=100'
          if (email !== null && email.substring(email.length - 7) === '@qq.com') {
            const qqId = email.substring(0, email.indexOf('@'))
            this.avatarUrl = 'http://q1.qlogo.cn/g?b=qq&nk=' + qqId + '&s=100'
          }
        }
      })
    },
    goBlogEdit (blogId) {
      this.$router.push({
        name: 'BlogEdit',
        params: { blogId: blogId }
      })
    },
    createBlog () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/blog/createBlog',
        params: {
          title: this.form.title
        }
      }).then(res => {
        if (res.data.status) {
          this.$message.success(res.data.message)
          this.$router.push({
            name: 'BlogEdit',
            params: { blogId: res.data.data.id }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.avatarImage {
  float: left;
  border-radius: 5px;
  height: 80px;
  width: 80px;
  vertical-align: middle;
  margin-bottom: 20px;
}

.username {
  margin-left: 20px;
  font-size: 20px;
}

.avatarSection img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}
</style>
